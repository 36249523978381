import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout/Layout"
import SEO from "../components/SEO/SEO"
import PostPreview from "../components/posts/PostPreview/PostPreview"

const BooksPage: React.FC<{
  data: Queries.BooksPostPreviewQuery
}> = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Books"
        description="Posts about books including book reviews, book topics, or my favorite books."
        image="https://cdn.sanity.io/images/zlybple6/production/ada257f4bf0fb34f744576d8517e8b381d5bb9ee-1280x516.jpg?w=1280&h=516&auto=format"
        article={false}
      />
      <div
        style={{
          maxWidth: `1000px`,
          margin: `auto`,
          padding: `56px 32px`,
          background: `radial-gradient(circle, rgba(235,235,235,1) 0%, rgba(255,255,255,1) 100%)`,
        }}
      >
        <h1>Posts about Books</h1>
        <div
          style={{
            marginBottom: `56px`,
            display: `flex`,
            flexDirection: `column`,
          }}
        >
          {data?.allSanityPost?.edges.map(({ node }) => {
            return <PostPreview node={node} />
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BooksPostPreview {
    allSanityPost(
      sort: { order: DESC, fields: publishedAt }
      filter: { categories: { elemMatch: { title: { ne: "tech" } } }, tags: { elemMatch: { name: { eq: "books" } } } }
    ) {
      edges {
        node {
          ...PostPreviewDetails
        }
      }
    }
  }
`

export default BooksPage
